import styled from "styled-components"
import * as fontFiles from "./fonts-list";

const GlobalStyle = styled.div`
  @font-face {
    font-family: 'EquipExtended';
    font-weight: bold;
    font-style: normal;
    font-display: auto;
    src: local('EquipExtendedBold'), url(${fontFiles.EquipExtendedBoldTTF}) format('font/truetype ttf'), url(${fontFiles.EquipExtendedBoldWOFF}) format('woff');
  }

  @font-face {
    font-family: 'EquipExtended';
    font-weight: 500;
    font-style: normal;
    font-display: auto;
    src: local('EquipExtendedMedium'), url(${fontFiles.EquipExtendedMediumTTF}) format('font/truetype ttf'), url(${fontFiles.EquipExtendedMediumWOFF}) format('woff');
  }

  @font-face {
    font-family: 'EquipExtended';
    font-weight: 200;
    font-style: normal;
    font-display: auto;
    src: local('EquipExtendedExtraLight'), url(${fontFiles.EquipExtendedExtraLightTTF}) format('font/truetype ttf'), url(${fontFiles.EquipExtendedExtraLightWOFF}) format('woff');
  }

  @font-face {
    font-family: 'EquipExtended';
    font-weight: 300;
    font-style: normal;
    font-display: auto;
    src: local('EquipExtendedLight'), url(${fontFiles.EquipExtendedLightTTF}) format('font/truetype ttf'), url(${fontFiles.EquipExtendedLightWOFF}) format('woff');
  }

  @font-face {
    font-family: 'EquipExtended';
    font-weight: normal;
    font-style: normal;
    font-display: auto;
    src: local('EquipExtendedMediumRegular'), url(${fontFiles.EquipExtendedRegularTTF}) format('font/truetype ttf'), url(${fontFiles.EquipExtendedRegularWOFF}) format('woff');
  }


  @font-face {
    font-family: 'FedraArabic';
    font-weight: 300;
    font-style: normal;
    font-display: auto;
    src: local('FedraArabicLt'), url(${fontFiles.FedraArabicLightTTF}) format('font/truetype ttf'), url(${fontFiles.FedraArabicLightTTF}) format('woff');
  }

  @font-face {
    font-family: 'FedraArabic';
    font-weight: normal;
    font-style: normal;
    font-display: auto;
    src: local('FedraArabicBk'), url(${fontFiles.FedraArabicBookTTF}) format('font/truetype ttf'), url(${fontFiles.FedraArabicBookTTF}) format('woff');
  }

  @font-face {
    font-family: 'FedraArabic';
    font-weight: 500;
    font-style: normal;
    font-display: auto;
    src: local('FedraArabicLtMd'), url(${fontFiles.FedraArabicMediumTTF}) format('font/truetype ttf'), url(${fontFiles.FedraArabicMediumTTF}) format('woff');
  }

  @font-face {
    font-family: 'FedraArabic';
    font-weight: bold;
    font-style: normal;
    font-display: auto;
    src: local('FedraArabicLtBld'), url(${fontFiles.FedraArabicBoldTTF}) format('font/truetype ttf'), url(${fontFiles.FedraArabicBoldTTF}) format('woff');
  }
`

export default GlobalStyle