import React from 'react';
import animationOn from '../../public/on-state.json'
import animationOff from '../../public/off-state.json'
import Lottie from 'lottie-react-web'
import { TweenMax, Linear } from 'gsap';
import ReactHowler from 'react-howler'

export let canPlay = false;


export default class SoundController extends React.Component{
    constructor (props) {
        super(props)
    
        this.state = {
            playing: false,
            stopOn: true,
            stopOff: false, 
            loop: true,
            volume: 0.05,
            controllerText: 'off'
        }
        this.toggle = this.toggle.bind(this);
        this.handleScroll= this.handleScroll.bind(this);
        this.allAnimations = [];
        this.isHidden = false;
    }

    componentDidMount(){
        if(canPlay){
            this.setState({
                playing: true,
                stopOn: false,
                stopOff: true
            });
            window.Howler.mute(false);
        } 
        
        TweenMax.to(document.querySelector(".sound-controller"), 1, {
            opacity: 1,
            delay: 0.3
        })

        TweenMax.to(document.querySelector(".tooltip-container"), 0.8, {
            x: -10,
            repeat: -1, 
            yoyo: true,
            ease: Linear.easeNone
        });
        this.allAnimations = Array.from(document.querySelectorAll(".sound-controller div"));
        window.addEventListener('scroll', this.handleScroll);
    }

    handleScroll(event){
        let scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop)
        if (this.lastScroll - scrollTop < 0) {
            this.direction = 'down';
        } else if (this.lastScroll - scrollTop > 0) {
            this.direction = 'up';
        }
    
        this.lastScroll = scrollTop <= 0 ? 0 : scrollTop;
      
        if(scrollTop > window.innerHeight/2 && !this.isHidden){
            this.isHidden = true;
            document.querySelector(".tooltip-container").classList.add("done");
        }
    }


    toggle() {
        if(this.state.playing){
            this.setState({
                playing: false,
                stopOn: true,
                stopOff: false, 
                controllerText: 'off'
            });

            this.allAnimations[0].style.display = 'none';
            this.allAnimations[1].style.display = 'block';
            window.Howler.mute(true);
            canPlay = false;
            document.querySelector(".sound-controller span:last-of-type").innerHTML = 'off';
        } else {
            this.setState({
                playing: true,
                stopOn: false,
                stopOff: true, 
                controllerText: 'on'
            });
            this.allAnimations[0].style.display = 'block';
            this.allAnimations[1].style.display = 'none';
            window.Howler.mute(false);
            canPlay = true;
            document.querySelector(".sound-controller span:last-of-type").innerHTML = 'on';
            document.querySelector(".tooltip-container").classList.add("done");
        }
    }

    render(){
        return (
            <div>
                <ReactHowler
                    src={'../ziqqi.mp3'}
                    playing={this.state.playing}
                    loop={true}
                    volume={this.state.volume}
                />
                <div className="sound-controller" onClick={this.toggle}>
                    <Lottie isPaused={!true}
                            options={{
                                animationData: animationOn,
                                loop: true,
                                autoPlay: true
                            }}
                    />
                     <Lottie isStopped={false}
                            segments={[150, 175]}
                            forceSegment={true}
                            options={{
                                animationData: animationOff,
                                loop:false
                            }}
                    />
                    <div className="sound-text">
                        <span>Sound</span>
                        <span>{this.state.controllerText}</span>
                    </div>  
                    <div className="tooltip-container">
                        <svg viewBox="0 0 86.6 64.65">
                            <path d="M34.34 5.17L0 64.65h86.6L52.26 5.17a10.35 10.35 0 0 0-17.92 0z"/>
                        </svg>
                        <span>Click for full experience</span>
                    </div>
                </div> 
            </div>
        )
    }
}

