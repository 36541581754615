import { Link } from "gatsby"
import PropTypes from "prop-types"
import SoundController from "../components/sound-controller"
import React from "react"

import "./global.scss"

const Header = ({ siteTitle }) => (
  <React.Fragment>
    <SoundController />
    <header>
      <ul>
        <li>
          <Link activeClassName="active" to="/">Ziqqi</Link>
        </li>
        <li>
          <Link activeClassName="active" to="/ziqqi-mart/" activeStyle={{ color: "black", backgroundColor: "white" }}>Ziqqi Mart</Link>
        </li>
        <li>
          <Link activeClassName="active" to="/ziqqi-fresh/" activeStyle={{ color: "black", backgroundColor: "white" }}>Ziqqi Fresh</Link>
        </li>
      </ul>
    </header>
  </React.Fragment>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
